var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-row',{staticClass:"cols--center mar-v--1"},[_c('div',{staticClass:"cell"}),_c('div',{staticClass:"table-pagination pull-right mar-v--1",attrs:{"name":"table-pagination"}},[_c('table-navigation',{attrs:{"state":_vm.state,"change-handler":_vm.load}})],1)]),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.state.loading),expression:"state.loading"}],class:_vm.model.name + '-table',attrs:{"name":_vm.model.name + '-table',"stripe":"","size":_vm.$vars.sizes.table || _vm.$vars.sizes.common,"data":_vm.state.items}},[_c('el-table-column',{attrs:{"prop":"id","label":_vm.$tf('id'),"width":"60"}}),_c('el-table-column',{attrs:{"prop":"thumbnail","label":_vm.$tf('common.detected'),"width":"176"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('object-thumbnail',{attrs:{"url":row.thumbnail || '',"frame":row.source_photo || ''},on:{"preview":function (v) { return _vm.showImage(row.source_photo, _vm.getBox(row)); }}})]}}])}),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"mar-v-1"},[_c('a',{attrs:{"name":"search-cases-btn","href":_vm.getSearchPath(row),"target":"_blank"}},[_c('i',{staticClass:"mar-left--1 el-icon-search"}),_vm._v(" "+_vm._s(_vm.$tfo('fast_case_search'))+" ")]),_c('div',{staticClass:"case-face__action-buttons"},[_c('el-button',{attrs:{"size":"small"},on:{"click":function () { return _vm.searchHandler(row); }}},[_vm._v(_vm._s(_vm.$tfo('cases_search')))]),_c('el-button',{attrs:{"size":"small"},on:{"click":function () { return _vm.searchDossier(row); }}},[_vm._v(_vm._s(_vm.$tfo('dossier_search')))]),(_vm.$store.getters.puppeteer)?_c('el-button',{attrs:{"size":"small"},on:{"click":function () { return _vm.searchExternal(row); }}},[_vm._v(_vm._s(_vm.$tf('external | search')))]):_vm._e()],1),_c('div',{staticClass:"mar-v--1"})])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$tf('video_archive'),"width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.hasVideos)?_c('span',[_vm._v(_vm._s(_vm.getVideoName(row)))]):_vm._e(),_vm._v(" "),_c('span',[_vm._v("(id: "+_vm._s(row.video_archive)+")")])]}}])}),_c('el-table-column',{attrs:{"width":"120","label":_vm.$tf('date')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm._f("parseDate")(row.created_date)))),_c('br'),_vm._v(" "+_vm._s(_vm._f("formatTime")(_vm._f("parseDate")(row.created_date)))),_c('br')]}}])})],1),_c('div',{staticClass:"table-pagination pull-right mar-v--1",attrs:{"name":"table-pagination"}},[_c('table-navigation',{attrs:{"state":_vm.state,"change-handler":_vm.load}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }